<template>
  <div :class="{ 'd-none': getIsSidebarHidden }" class="sidebar">
    <aside>
      <div class="logo">
        <a>
          <img v-if="teamLogo" :src="teamLogo" :alt="getCompanyName" />
        </a>
      </div>
      <div @click="toggleMobileMenu" :class="toggleSidebar ? 'open' : ''" id="menu-icon">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <ul :class="mobileMenu ? (toggleSidebar ? 'open-navbar' : 'close-navbar') : ''">
        <li
          v-show="currentPage.includes('home-value-report') && getIsHVRUpdated"
          class="gray-screen"
        ></li>
        <li
          v-for="(tab, index) in defaultTabs"
          :key="index"
          @mouseenter="hoveredTab = index + 1"
          @mouseleave="hoveredTab = 0"
          :class="
            tab === 'Home-Value-Report' && getIsHVRUpdated
              ? 'tooltip-active'
              : currentPage.includes(tab.toLowerCase())
              ? 'active'
              : ''
          "
        >
          <router-link :to="`/${tab.toLowerCase()}`">
            <CreditMonitoringIcon
              v-if="tab === 'Credit-Monitoring'"
              :color="
                currentPage.includes(tab.toLowerCase())
                  ? getAssets.primaryBrandColor
                  : '#848692'
              "
            />
            <HvrSidebarIcon
              v-else-if="tab === 'Home-Value-Report'"
              :color="
                currentPage.includes(tab.toLowerCase())
                  ? getAssets.primaryBrandColor
                  : '#848692'
              "
            />
            <i :class="getSideBarIcons(tab)" v-else></i>
            {{ tab.replaceAll("-", " ") }}
          </router-link>
          <div
            v-if="tab === 'Home-Value-Report' && getIsHVRUpdated"
            class="tooltip-sidebar"
          >
            <div class="tooltip-inner">
              <p>Your Home Value Report has been updated!</p>
            </div>
          </div>
          <span
            v-if="isShowMortgageEstimateNew(tab) && mtgEstimateCount"
            class="sidebar-badge"
          >
            {{ mtgEstimateCount }}
          </span>
          <img
            v-show="
              mobileMenu ||
              hoveredTab === index + 1 ||
              getAssets.clientPortalPinnedTab === tab
            "
            role="button"
            @click="pinTheTab(tab)"
            :src="tabWithPin(tab)"
            class="pe-2"
          />
        </li>
        <li class="position-unset">
          <router-link :to="'/logout'">
            <i class="fas fa-sign-out-alt"></i>
            Logout
          </router-link>
        </li>
      </ul>

      <!-- <div
        class="user-info"
        :class="
          mobileMenu ? (toggleSidebar ? 'open-navbar' : 'close-navbar') : ''
        "
      >
        <div class="avatar">
          <img v-if="getUser.profilePhotoURL" :src="getUser.profilePhotoURL" />
          <img v-else :src="defaultAvatar()" />
        </div>
        <div class="name-designation">
          <h5>{{ getUser.name }}</h5>
          <span v-if="getUser.title && getUser.title !== ''">{{
            getUser.title
          }}</span>
        </div>
        <div class="contact-social">
          <a
            v-if="getUser.phone"
            role="button"
            :href="'tel:' + getUser.phone"
            >{{ getUser.phone }}</a
          >
          <a
            v-if="getUser.email"
            role="button"
            :href="'mailto:' + getUser.email"
            >{{ getUser.email }}</a
          >
          <ul>
            <li v-if="getUser.facebookURL">
              <a :href="getUser.facebookURL" target="_blank"
                ><img
                  src="../assets/images/facebook-icon.svg"
                  alt="facebook icon"
              /></a>
            </li>
            <li v-if="getUser.instagramURL">
              <a :href="getUser.instagramURL" target="_blank"
                ><img
                  src="../assets/images/instagram-icon.svg"
                  alt="instagram icon"
              /></a>
            </li>
            <li v-if="getUser.twitterURL">
              <a :href="getUser.twitterURL" target="_blank"
                ><img
                  src="../assets/images/twitter-icon.svg"
                  alt="twitter icon"
              /></a>
            </li>
          </ul>
          <div class="equal-housing-logo">
            <img
              src="../assets/images/equal-housing-logo.png"
              alt="Equal Housing Logo"
            />
          </div>
        </div>
      </div> -->
    </aside>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "./../event-bus";
import Toasters from "../mixins/toasters";
import CreditMonitoringIcon from "./Icons/CreditMonitoringIcon";
import HvrSidebarIcon from "./Icons/HvrSidebarIcon.vue";
import introJS from "intro.js";

export default {
  name: "Sidebar",
  components: { CreditMonitoringIcon, HvrSidebarIcon },
  data() {
    return {
      teamLogo: "",
      toggleSidebar: false,
      mobileMenu: false,
      hoveredTab: 0,
      windowWidth: window.innerWidth,
      companyname: "",
    };
  },
  mixins: [Toasters],
  computed: {
    ...mapGetters([
      "getOrgId",
      "getProspectId",
      "getUser",
      "getAssets",
      "getTabs",
      "getCompanyName",
      "getPlatform",
      "getIsHVRUpdated",
      "getIsSidebarHidden",
    ]),
    ...mapGetters("home-page", ["mtgEstimateCount"]),
    ...mapGetters("mortgage-estimate", ["isShowMortgageEstimateMenu", "isShowWelcome"]),

    currentPage() {
      return this.$route.path;
    },

    defaultTabs() {
      if (this.getAssets.scenarioCount === 0) {
        return this.getTabs.filter((e) => e !== "Mortgage-Estimates");
      }
      return this.getTabs;
    },
  },
  watch: {
    $route() {
      if (this.mobileMenu) this.toggleSidebar = false;
    },
    windowWidth(newVal) {
      if (newVal < 768) this.mobileMenu = true;
      else this.mobileMenu = false;
    },
    isShowMortgageEstimateMenu: {
      handler() {
        if (!this.getIsSidebarHidden) {
          setTimeout(() => {
            this.$nextTick(() => {
              if (!this.mobileMenu) {
                this.intro();
              }
            });
          }, 1000);
        }
      },
    },
  },
  created() {
    window.addEventListener("resize", this.getScreenSize);
  },
  mounted() {
    this.teamLogo = this.getAssets.horizontalTeamLogo;
    this.companyname = this.$localStore.getItem("company_name");
    if (this.windowWidth < 768) this.mobileMenu = true;
    else this.mobileMenu = false;
  },
  methods: {
    ...mapActions(["fetchOrgId", "fetchUser", "fetchAssets"]),
    ...mapActions("mortgage-estimate", ["updateShowMortgageEstimateMenu"]),

    getSideBarIcons(value) {
      if (value === "Approval-Letters") return "fa fa-envelope";
      if (value === "Mortgage-Tasks") return "fa fa-list";
      if (value === "Mortgage-Estimates") return "fas fa-chart-bar";
      // if (value === "Credit-Monitoring") return "fa fa-line-chart";
      if (value === "Mortgage-Calculator") return "fa fa-calculator";
      if (value === "Condos") return "fa fa-building";
      return;
    },

    tabWithPin(value) {
      let thePinColor;

      if (this.getAssets.primaryBrandColor) {
        thePinColor = this.getAssets.primaryBrandColor.substring(1);
      } else {
        let color = "#A6383C";
        thePinColor = color.substring(1);
      }

      if (this.getAssets.clientPortalPinnedTab === value)
        return `https://img.icons8.com/glyph-neue/18/${thePinColor}/pin.png`;
      else return "https://img.icons8.com/plumpy/16/0000F0/pin3.png";
    },

    defaultAvatar() {
      let theAvatarColor;

      if (this.getAssets.primaryBrandColor) {
        theAvatarColor = this.getAssets.primaryBrandColor.substring(1);
      } else {
        let color = "#A6383C";
        theAvatarColor = color.substring(1);
      }

      return `https://img.icons8.com/material-rounded/36/${theAvatarColor}/user-male-circle.png`;
    },

    async pinTheTab(value) {
      eventBus.$emit("loader-status", true);

      let payLoad = {
        prospectId: this.getProspectId,
        pinnedTab: value,
        orgId: this.getOrgId,
      };

      await this.$http
        .post("/account/PostConfiguration/", payLoad)
        .then((response) => {
          if (response.data.response === "Success") {
            this.fetchAssets(response.data.assetData);
            this.success("Pinned Tab successfully updated");
          }
        })
        .catch((error) => {
          console.log(error);
        });

      eventBus.$emit("loader-status", false);
    },

    getScreenSize() {
      this.windowWidth = window.innerWidth;
    },

    toggleMobileMenu() {
      this.toggleSidebar = !this.toggleSidebar;
    },

    isShowMortgageEstimateNew(name) {
      if (!this.mtgEstimateCount) return false;

      const currentPage = this.$route.name;
      if (currentPage !== "Mortgage-Estimats" && name === "Mortgage-Estimates") {
        return true;
      }
    },

    intro() {
      // const isMortgageEstimatePage = this.$route.name === "Mortgage-Estimate";
      if (
        this.getAssets.scenarioCount === 0 ||
        this.isShowWelcome ||
        !this.isShowMortgageEstimateMenu /** || isMortgageEstimatePage**/
      )
        return;

      const steps = [
        {
          element: "li:has(> a[href='/mortgage-estimates'])",
          title: "The new estimate is ready for your review!",
          intro: "Please note that the mortgage estimate information has been changed.",
          position: "right",
        },
      ];

      introJS("#intro-farm")
        .setOptions({
          showBullets: false,
          hidePrev: true,
          doneLabel: "Learn More",
          disableInteraction: true,
          // exitOnOverlayClick: false,
          steps,
        })
        .oncomplete(() => {
          console.log("oncomplete");
          // this.updateShowMortgageEstimateMenu();
          this.$router.push({ name: "Mortgage-Estimates" });
        })
        .onexit(() => {
          console.log("onexit");
          this.updateShowMortgageEstimateMenu();
        })
        .start();
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.getScreenSize);
  },
};
</script>

<style scoped>
.sidebar {
  z-index: 9;
}
.open-navbar {
  display: block !important;
}
.close-navbar {
  display: none !important;
}
.sidebar aside ul li.active a i,
.sidebar aside ul li:hover a i {
  color: var(--primary-color);
}
.sidebar aside ul li.active a svg path,
.sidebar aside ul li:hover a svg path {
  fill: var(--primary-color);
}
.sidebar aside ul li a svg {
  margin-right: 30px;
}

.sidebar aside ul li::after {
  background-color: var(--primary-color);
}
.sidebar aside .user-info .name-designation span {
  background-color: var(--primary-color);
  color: #ffffff;
}
.pin-color {
  color: var(--primary-color);
}

.sidebar-badge {
  width: 20px;
  height: 20px;
  background: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar li a {
  height: 100%;
}
.sidebar li.tooltip-active .tooltip-sidebar .tooltip-inner::before {
  content: "";
  position: absolute;
  left: -8px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid var(--primary-color);
}
.sidebar li.tooltip-active .tooltip-sidebar p {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  text-align: left;
}
</style>
