<template>
  <div class="wrapper">
    <div class="logout sign-in-up">
      <img
        v-if="getAssets?.horizontalTeamLogo"
        class="text-center mb-5 team-logo"
        :src="getAssets.horizontalTeamLogo"
        alt="Team-Logo"
      />
      <div class="overlay"></div>
      <div class="form pt-0 mt-4">
        <div class="form__content">
          <div class="lock-icon mb-3">
            <span>
              <LogoutIcon />
            </span>
          </div>
          <h2>You are logged out</h2>

          <p>Need to log in to continue</p>

          <div class="button">
            <router-link
              :to="'/' + getCompanyName + '/' + getProspectId + '&' + getOrgId"
              class="btn w-100 btn-red login"
            >
              <span>Log In</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toaster from "./../mixins/toasters";
import { mapActions, mapGetters } from "vuex";
import LogoutIcon from "./Icons/LogoutIcon";

export default {
  name: "Logout",
  components: { LogoutIcon },
  mixins: [Toaster],
  data() {
    return {
      portalBg: "",
    };
  },
  computed: {
    ...mapGetters([
      "getOrgId",
      "getCompanyName",
      "getProspectId",
      "getAssets",
      "getPlatform",
    ]),
    primaryColor() {
      return this.getAssets.primaryBrandColor || "#A6383C";
    },
  },
  mounted() {
    this.fetchUser({});
    this.portalBg = this.getAssets.agentPortalBackground;

    if (this.$localStore.getItem("cpl_reloaded")) {
      this.$localStore.removeItem("cpl_reloaded");
      this.$localStore.removeItem("authorized");
      this.$localStore.removeItem("default-tabs");
      this.$localStore.removeItem("RequestKey");
      this.$localStore.removeItem("ClientKey");
    } else {
      this.$localStore.setItem("cpl_reloaded", "1");

      this.$nextTick(() => {
        window.location.reload();
      });
    }
  },
  watch: {
    portalBg(newVal) {
      if (newVal) {
        document.body.style.backgroundImage = `url(${newVal})`;
        document.body.style.backgroundPosition = "top-center";
        document.body.style.backgroundSize = "cover";
      }
    },
  },
  methods: {
    ...mapActions(["fetchUser"]),
  },
};
</script>

<style scoped>
@import "./../assets/css/logout-page.css";

.btn.btn-red:before {
  background: var(--primary-color) !important;
}
.btn.btn-red:after {
  border: 1px solid var(--primary-color) !important;
}
.btn:hover,
.btn:focus {
  background-color: #fff !important;
}
.btn.btn-red:hover span,
.btn.btn-white span,
.input-group-text {
  color: var(--primary-color) !important;
}
</style>
