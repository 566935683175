import Vue from "vue";
import Vuex from "vuex";
import { EncryptStorage } from "encrypt-storage";
import User from "./modules/user";
import HomePage from "./modules/home-page";
import MortgageEstimate from "./modules/mortgage-estimate";
import Common from "./modules/common";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

// Initialize EncryptStorage
const encryptStorage = new EncryptStorage("your-secret-key", {
  storageType: "sessionStorage",
  stateManagementUse: true,
});

export default new Vuex.Store({
  modules: {
    User,
    "home-page": HomePage,
    "mortgage-estimate": MortgageEstimate,
    common: Common,
  },
  plugins: [
    createPersistedState({
      paths: ["User", "home-page"],
      storage: {
        getItem: (key) => {
          try {
            return encryptStorage.getItem(key) ?? null; // Ensure it returns `null` if undefined
          } catch (error) {
            console.error("Error retrieving data:", error);
            return null;
          }
        },
        setItem: (key, value) => {
          try {
            encryptStorage.setItem(key, value);
          } catch (error) {
            console.error("Error setting data:", error);
          }
        },
        removeItem: (key) => {
          try {
            encryptStorage.removeItem(key);
          } catch (error) {
            console.error("Error removing data:", error);
          }
        },
      },
    }),
  ],
});
