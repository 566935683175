var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more-info-table"},[_c('b-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPaymentBreakdown),expression:"!isPaymentBreakdown"}],ref:"moreInfoTable",attrs:{"id":_vm.getAssets.loanPurposeType === _vm.refinanceLoanType && _vm.isCashToClose
        ? 'loan-purpose-refinance-table'
        : 'bTable',"thead-class":{
      'thead-info-active': _vm.show,
    },"tbody-class":{
      'more-info-table-active': _vm.show,
      'table-from-bottom': !_vm.isPaymentBreakdown,
    },"tfoot-class":{
      'more-info-table-active': _vm.show,
      'foot-from-bottom': !_vm.isPaymentBreakdown,
    },"sticky-header":"","busy":_vm.loading,"items":_vm.value,"fields":_vm.fields},scopedSlots:_vm._u([(_vm.isPdf)?{key:"head(description)",fn:function({ label }){return [_c('p',{staticClass:"label-pdf-detail"},[_vm._v("Fee Detail")]),_c('p',{staticClass:"label-pdf-header"},[_vm._v(_vm._s(label))])]}}:null,_vm._l((_vm.fields),function(field,index){return {key:`cell(${field.key})`,fn:function({ item }){return [(
          item.cashToCloseBreakdown === 'SP' && field.key === 'cashToCloseBreakdown'
        )?_c('span',[_vm._v(" "+_vm._s(_vm.getSpLabel())+" ")]):(
          (item.cashToCloseBreakdown === 'Seller Credit(s)' ||
            item.cashToCloseBreakdown === 'Earnest Money') &&
          field.key !== 'cashToCloseBreakdown'
        )?_c('span',{staticClass:"green-fonts"},[_vm._v(_vm._s(_vm._f("currencyOrPercent")(item[field.key],item)))]):(
          (item.cashToCloseBreakdown === 'Cash to Close' &&
            field.key !== 'cashToCloseBreakdown') ||
          (item.paymentBreakdown === 'Total Payment' &&
            field.key !== 'paymentBreakdown')
        )?_c('span',{staticClass:"custom-badge"},[_vm._v(_vm._s(_vm._f("currencyOrPercent")(item[field.key],item)))]):_c('span',{class:{ 'status-no': item[field.key] <= 0 }},[_vm._v(_vm._s(_vm._f("currencyOrPercent")(item[field.key],item)))]),(item[field.key] === 'PMI' || item[field.key] === 'HOI')?_c('info-tooltip',{attrs:{"info":item.info}}):_vm._e()]}}}),{key:"cell(paidBy)",fn:function({ item }){return [_c('span',{staticClass:"paid-by-col"},[_vm._v(_vm._s(item.paidBy))])]}},{key:"cell(aprFee)",fn:function({ item }){return [_c('span',{class:{ 'status-no': !item.aprFee }},[_vm._v(_vm._s(item.aprFee ? "Yes" : "No"))])]}},{key:"cell(financed)",fn:function({ item }){return [_c('span',{class:{ 'status-no': !item.financed }},[_vm._v(_vm._s(item.financed ? "Yes" : "No"))])]}},{key:"cell(total)",fn:function({ item }){return [_c('span',{class:{ 'status-no': item.total <= 0 }},[_vm._v(_vm._s(_vm._f("currencyAbsolute")(item.total)))])]}},{key:"cell(prepaidEscrows)",fn:function({ item }){return [_c('span',{class:{ 'status-no': !item.prepaidEscrows }},[_vm._v(_vm._s(item.prepaidEscrows ? "Yes" : "No"))])]}},(_vm.footers.length > 0)?{key:"custom-foot",fn:function({}){return _vm._l((_vm.footers),function(ft,idx){return _c('td',{key:idx},[_c('span',{on:{"click":function($event){return _vm.$emit('detail', ft)}}},[_vm._v(_vm._s(ft.label))]),(idx !== 0)?_c('arrow-up-icon',{staticClass:"ml-2"}):_vm._e()],1)})}}:null],null,true)}),(_vm.isPaymentBreakdown)?_c('more-info-payment-breakdown-table',{attrs:{"current-mortgage-index":_vm.currentMortgageIndex,"all-scenarios":_vm.allScenarios,"show-debt-tooltip":_vm.showDebtTooltip},on:{"click":_vm.showHideTooltip}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }