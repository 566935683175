<template>
  <b-modal
    v-model="showWelcome"
    modal-class="welcome-modal"
    centered
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <close-button @click="onClosePopup" />

    <svg
      width="100%"
      height="auto"
      viewBox="0 0 650 270"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9.99999C0 4.47715 4.47715 0 10 0H640C645.523 0 650 4.47715 650 10V270H0V9.99999Z"
        fill="#F6F8FA"
      />
      <path
        d="M563 101C537.8 55 500.167 42.1667 484.5 41.5L0 270H635C641 236 636 204.5 621 184.5C609 168.5 586 163.167 576 162.5C576.4 149.7 567.5 116.167 563 101Z"
        fill="#D7DAE3"
        fill-opacity="0.4"
      />
      <rect x="191" y="58" width="99" height="54" fill="#9E795E" />
      <path
        d="M271.866 0L189 202.251L208.968 251.568L250.401 270H413.637L498 64.7601L342.252 0H271.866Z"
        fill="white"
      />
      <rect
        x="324.688"
        y="89.2012"
        width="67.4311"
        height="47.4563"
        rx="2"
        transform="rotate(22.6956 324.688 89.2012)"
        :fill="hexToRGBA($localStore.getItem('primary-color') || '#A6383C', 0.2)"
      />
      <rect
        x="230.955"
        y="122.648"
        width="82.2066"
        height="10"
        rx="5"
        transform="rotate(22.6956 230.955 122.648)"
        :fill="hexToRGBA($localStore.getItem('primary-color') || '#A6383C', 0.5)"
      />
      <rect
        x="257.688"
        y="61.2012"
        width="67.4311"
        height="47.4563"
        rx="2"
        transform="rotate(22.6956 257.688 61.2012)"
        :fill="hexToRGBA($localStore.getItem('primary-color') || '#A6383C', 0.2)"
      />
      <rect
        x="277.688"
        y="13.2012"
        width="140.703"
        height="47.4563"
        rx="2"
        transform="rotate(22.6956 277.688 13.2012)"
        :fill="hexToRGBA($localStore.getItem('primary-color') || '#A6383C', 0.2)"
      />
      <rect
        x="224.564"
        y="138.185"
        width="213.434"
        height="47.4563"
        rx="2"
        transform="rotate(22.6956 224.564 138.185)"
        :fill="hexToRGBA($localStore.getItem('primary-color') || '#A6383C', 0.2)"
      />
      <path
        d="M166 212.5C164.8 212.9 150.167 221.333 143 225.5C134.5 206 119 166.3 125 163.5C131 160.7 138.833 144.667 142 137C146.333 123.333 155.3 95.2 156.5 92C157.7 88.8 163 70.6667 165.5 62C166 60.6667 168 57.5 172 55.5C176 53.5 187.667 51.3333 193 50.5C197.667 49.3333 210.5 45.7999 224.5 40.9999C238.5 36.1999 250.667 38.9999 255 40.9999L245 65.4999C242.6 65.8999 239.667 65.3333 238.5 64.9999C235.7 69.3999 224.333 73.4999 219 74.9999C218.6 87.3999 214.167 101.833 212 107.5C226.5 106.5 241 91.9999 245 86.9999C249 81.9999 261 69.4999 265 66.4999C269 63.4999 283 52.4999 290.5 63.9999C298 75.4999 288.5 85.4999 286.5 88.4999C284.5 91.4999 273.5 102 262.5 110C253.7 116.4 240.5 133.667 235 141.5C231.333 146.833 221.9 160.1 213.5 170.5C205.1 180.9 198.833 186.167 195.5 188C194.333 191.667 190.8 199.4 190 201C189 203 167.5 212 166 212.5Z"
        fill="#DFAA83"
      />
      <path
        d="M124.5 164C121.3 165.2 116.167 166.833 114 167.5C115.5 170 122.5 180.5 126.5 195C129.7 206.6 131.5 224.167 132 231.5L138 228.5C142 226.5 143 225.333 143 225C143.5 217 142.5 207 138.5 191C134.5 175 127.5 164.5 127 164C126.6 163.6 125.167 163.833 124.5 164Z"
        fill="white"
      />
      <path
        d="M0 171.073V270H85.9882L127.982 255.939C130.149 254.433 133.782 245.093 130.982 219.783C128.182 194.474 120.924 175.996 116.484 168.562C115.284 166.554 107.985 167.056 103.486 167.056L0 171.073Z"
        fill="#252525"
      />
      <mask
        id="mask0_1127_13777"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="650"
        height="270"
      >
        <rect width="650" height="270" fill="#F8EADF" />
      </mask>
      <g mask="url(#mask0_1127_13777)">
        <path
          d="M343.862 -25.1333L498.301 39.4558C503.396 41.5867 505.799 47.4446 503.668 52.5399L498.38 65.1837L334.716 -3.2638L343.862 -25.1333Z"
          :fill="$localStore.getItem('primary-color') || '#A6383C'"
        />
        <g filter="url(#filter0_d_1127_13777)">
          <mask
            id="mask1_1127_13777"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="188"
            y="0"
            width="310"
            height="271"
          >
            <path
              d="M271.5 0L188.5 202.625L208.5 252.034L250 270.5H413.5L498 64.8801L342 0H271.5Z"
              fill="#F4E5E5"
            />
          </mask>
          <g mask="url(#mask1_1127_13777)">
            <path
              d="M202.791 189.183C203.218 188.165 204.39 187.684 205.41 188.109L398.625 268.767C399.645 269.192 400.125 270.363 399.698 271.382L382.934 311.393C382.507 312.412 381.334 312.893 380.315 312.468L196.5 235.735L187.099 231.81C186.08 231.385 185.6 230.214 186.027 229.195L202.791 189.183Z"
              :fill="$localStore.getItem('primary-color') || '#A6383C'"
            />
            <rect
              width="132.19"
              height="14.9764"
              rx="7.48822"
              transform="matrix(0.922821 0.385229 -0.386441 0.922314 286.18 -9.15527)"
              :fill="$localStore.getItem('primary-color') || '#A6383C'"
            />
            <path
              d="M218.248 214.183L189 203L208.5 252.034L221.101 220.72C222.144 218.126 220.859 215.181 218.248 214.183Z"
              :fill="$localStore.getItem('primary-color') || '#A6383C'"
            />
          </g>
        </g>
      </g>
      <circle
        cx="486.5"
        cy="47.5"
        r="7.5"
        :fill="hexToRGBA($localStore.getItem('primary-color') || '#A6383C', 0.2)"
      />
      <rect
        x="400.923"
        y="193.546"
        width="28.0443"
        height="10"
        rx="5"
        transform="rotate(22.6956 400.923 193.546)"
        :fill="hexToRGBA($localStore.getItem('primary-color') || '#A6383C', 0.2)"
      />
      <rect
        x="411.295"
        y="42.6553"
        width="21.6503"
        height="10.966"
        rx="5.48301"
        transform="rotate(22.6956 411.295 42.6553)"
        :fill="$localStore.getItem('primary-color') || '#A6383C'"
      />
      <rect
        x="390.688"
        y="117.201"
        width="67.4311"
        height="47.4563"
        rx="2"
        transform="rotate(22.6956 390.688 117.201)"
        :fill="hexToRGBA($localStore.getItem('primary-color') || '#A6383C', 0.2)"
      />
      <rect
        x="411.688"
        y="69.2012"
        width="67.4311"
        height="47.4563"
        rx="2"
        transform="rotate(22.6956 411.688 69.2012)"
        :fill="hexToRGBA($localStore.getItem('primary-color') || '#A6383C', 0.2)"
      />
      <rect
        x="443.255"
        y="29.5869"
        width="64.5943"
        height="120.048"
        rx="5"
        transform="rotate(22.6956 443.255 29.5869)"
        fill="#500000"
        fill-opacity="0.2"
      />
      <g filter="url(#filter1_d_1127_13777)">
        <rect
          x="447.255"
          y="50.5869"
          width="156.887"
          height="120.048"
          rx="5"
          transform="rotate(22.6956 447.255 50.5869)"
          fill="white"
        />
      </g>
      <circle
        cx="455.5"
        cy="82.5"
        r="8.5"
        :fill="$localStore.getItem('primary-color') || '#A6383C'"
      />
      <circle
        cx="443.5"
        cy="113.5"
        r="8.5"
        :fill="$localStore.getItem('primary-color') || '#A6383C'"
      />
      <circle
        cx="431.5"
        cy="143.5"
        r="8.5"
        :fill="$localStore.getItem('primary-color') || '#A6383C'"
      />
      <rect
        x="477.543"
        y="86.541"
        width="98.2857"
        height="10"
        rx="5"
        transform="rotate(22.6956 477.543 86.541)"
        :fill="hexToRGBA($localStore.getItem('primary-color') || '#A6383C', 0.2)"
      />
      <rect
        x="465.823"
        y="117.658"
        width="97.1624"
        height="10"
        rx="5"
        transform="rotate(22.6956 465.823 117.658)"
        :fill="hexToRGBA($localStore.getItem('primary-color') || '#A6383C', 0.2)"
      />
      <rect
        x="454.078"
        y="148.765"
        width="94.9777"
        height="10"
        rx="5"
        transform="rotate(22.6956 454.078 148.765)"
        :fill="hexToRGBA($localStore.getItem('primary-color') || '#A6383C', 0.2)"
      />
      <defs>
        <filter
          id="filter0_d_1127_13777"
          x="176.5"
          y="-8"
          width="241.357"
          height="294.5"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1127_13777"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1127_13777"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1127_13777"
          x="390.477"
          y="44.1274"
          width="211.976"
          height="192.204"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1127_13777"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1127_13777"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
    <div class="welcome-content" :style="cssProps">
      <h2 class="mb-1">Hi {{ getUser.firstName }}!</h2>
      <div class="welcome-content__message d-flex align-items-center">
        <p v-if="welcomeMessage" class="text-left">
          {{ welcomeMessage }}
        </p>
        <outlined-button @click="onClosePopup" text="Continue" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import OutlinedButton from "@/components/commons/atoms/OutlinedButton";
import CloseButton from "@/components/commons/atoms/CloseButton";
import { mapActions, mapGetters } from "vuex";
import Shades from "@/mixins/shades";

export default {
  name: "WelcomeDialog",
  mixins: [Shades],
  components: {
    CloseButton,
    OutlinedButton,
  },
  computed: {
    ...mapGetters("mortgage-estimate", ["welcomeMessage", "isShowWelcome"]),

    ...mapGetters(["getUser"]),
    ...mapGetters(["getAssets"]),

    cssProps() {
      return {
        "--primary-color": this.getAssets.primaryBrandColor || "#c34132",
        "--bg-primary-color": this.getAssets.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets.primaryBrandColor, 2)
          : "#FFCCCF",
        "--blink-shade": this.getAssets.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets.primaryBrandColor, 1.2)
          : "#eb1c2b",
        "--shade-one": this.getShadesOfColor(
          this.getAssets.primaryBrandColor || "#c34132",
          -0.1
        ),
        "--shade-two": this.getShadesOfColor(
          this.getAssets.primaryBrandColor || "#c34132",
          -0.3
        ),
        "--shade-three": this.getShadesOfColor(
          this.getAssets.primaryBrandColor || "#c34132",
          0
        ),
        "--shade-four": this.getShadesOfColor(
          this.getAssets.primaryBrandColor || "#c34132",
          -0.2
        ),
        "--poor": this.getAssets.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets.primaryBrandColor, 1.4)
          : "#FFCCCF",
        "--fair": this.getAssets.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets.primaryBrandColor, 0.9)
          : "#FF7B84",
        "--good": this.getAssets.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets.primaryBrandColor, -0.1)
          : "#D1202D",
        "--very-good": this.getAssets.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets.primaryBrandColor, -0.3)
          : "#D1202D",
        "--exceptional": this.getAssets.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets.primaryBrandColor, -0.5)
          : "#94000B",
      };
    },

    scenarioId() {
      return this.$route.query["scenario-id"];
    },

    showWelcome() {
      if (!this.scenarioId) {
        return this.isShowWelcome;
      }

      return false;
    },
  },
  methods: {
    ...mapActions("mortgage-estimate", ["updateShowWelcome"]),

    onClosePopup() {
      this.updateShowWelcome();
    },
  },
};
</script>

<style lang="scss">
.welcome-modal {
  .modal-dialog,
  .modal-content {
    width: 652px !important;
    max-width: 652px !important;
  }

  .modal-content {
    border-radius: 10px;
  }

  .modal-body {
    padding: 0 !important;
  }

  .welcome-content {
    padding: 30px 28px 16px 28px;

    &__message {
      gap: 28px;
      .outlined-btn {
        padding: 0 25.5px;
      }
    }

    img {
      height: 270px;
    }

    h2 {
      font-weight: 600;
      font-size: 25px;
      line-height: 38px;
    }

    p {
      opacity: 0.7;
    }
  }
}

@media all and (max-width: 767px) {
  .welcome-modal {
    .modal-dialog {
      width: 100% !important;
      max-width: calc(100% - 28px) !important;
      margin: auto;
      padding: 0;
    }
    .modal-content {
      width: 100% !important;
      max-width: unset !important;
    }

    .b-btn-close {
      right: 0;
      top: -40px;
    }
  }
}

@media all and (max-width: 599px) {
  .welcome-modal {
    .welcome-content__message {
      flex-direction: column;
      align-items: flex-start !important;
    }
    .welcome-content {
      padding: 30px 28px 44px 14px;
    }
  }
}
</style>
